package com.eltonkola.components.icons.ekicon

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.eltonkola.components.icons.EKIcon

public val EKIcon.FlagAl: ImageVector
    get() {
        if (_flagAl != null) {
            return _flagAl!!
        }
        _flagAl = Builder(name = "FlagAl", defaultWidth = 50.0.dp, defaultHeight = 34.0.dp,
                viewportWidth = 50.0f, viewportHeight = 34.0f).apply {
            path(fill = SolidColor(Color(0xFFFF4B55)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(49.1382f, 33.3792f)
                horizontalLineTo(0.8625f)
                curveTo(0.3864f, 33.3792f, 4.0E-4f, 32.9932f, 4.0E-4f, 32.5171f)
                verticalLineTo(1.4828f)
                curveTo(4.0E-4f, 1.0067f, 0.3864f, 0.6207f, 0.8625f, 0.6207f)
                horizontalLineTo(49.1383f)
                curveTo(49.6144f, 0.6207f, 50.0004f, 1.0067f, 50.0004f, 1.4828f)
                verticalLineTo(32.5171f)
                curveTo(50.0003f, 32.9933f, 49.6143f, 33.3792f, 49.1382f, 33.3792f)
                close()
            }
            path(fill = SolidColor(Color(0xFF464655)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(34.4293f, 21.0813f)
                curveTo(33.9579f, 20.785f, 33.6751f, 21.2564f, 33.6751f, 21.2564f)
                lineTo(32.3011f, 21.418f)
                lineTo(32.988f, 20.8388f)
                curveTo(33.4864f, 20.8793f, 33.5538f, 20.7311f, 33.6884f, 20.5291f)
                curveTo(33.8231f, 20.327f, 34.3888f, 20.4213f, 34.3888f, 20.4213f)
                curveTo(34.308f, 19.8017f, 33.5267f, 20.1519f, 33.5267f, 20.1519f)
                curveTo(32.8936f, 20.0845f, 32.7859f, 20.5964f, 32.7859f, 20.5964f)
                lineTo(31.8026f, 21.3507f)
                lineTo(29.6386f, 20.7095f)
                curveTo(29.863f, 20.4623f, 30.0393f, 20.2186f, 30.065f, 20.0711f)
                curveTo(30.1081f, 19.8233f, 28.4667f, 19.4065f, 27.9637f, 18.8857f)
                curveTo(27.4609f, 18.3648f, 27.2813f, 17.5387f, 27.5148f, 17.3232f)
                curveTo(27.7482f, 17.1077f, 27.9459f, 17.7182f, 28.1972f, 18.2212f)
                curveTo(28.4486f, 18.7241f, 28.9157f, 19.1191f, 29.221f, 18.8677f)
                curveTo(29.5262f, 18.6164f, 28.8977f, 18.2392f, 28.6103f, 17.7004f)
                curveTo(28.323f, 17.1616f, 28.8258f, 16.9639f, 29.0593f, 17.0178f)
                curveTo(29.2928f, 17.0717f, 29.4365f, 18.1853f, 29.9933f, 18.7599f)
                curveTo(30.55f, 19.3346f, 31.0529f, 19.2268f, 31.1068f, 18.8676f)
                curveTo(31.1607f, 18.5085f, 30.188f, 18.0659f, 29.9903f, 17.7605f)
                curveTo(29.7927f, 17.4552f, 29.7418f, 16.8561f, 30.0651f, 16.8382f)
                curveTo(30.3883f, 16.8203f, 31.5737f, 18.5869f, 32.5795f, 19.2629f)
                curveTo(33.6751f, 19.9992f, 34.555f, 19.1371f, 34.4115f, 18.7779f)
                curveTo(34.2678f, 18.4186f, 33.6033f, 18.5624f, 32.831f, 18.3647f)
                curveTo(32.0587f, 18.1672f, 31.2685f, 17.1255f, 31.035f, 16.5508f)
                curveTo(30.8015f, 15.9761f, 30.9946f, 15.9761f, 31.1294f, 16.03f)
                curveTo(31.264f, 16.0839f, 31.7759f, 16.8382f, 32.7592f, 17.4174f)
                curveTo(33.7425f, 17.9966f, 34.4294f, 17.4444f, 34.5103f, 17.3097f)
                curveTo(34.5912f, 17.1751f, 34.9018f, 16.5704f, 33.8907f, 16.7126f)
                curveTo(32.7414f, 16.8742f, 32.0857f, 16.1918f, 31.6143f, 15.8281f)
                curveTo(31.1428f, 15.4644f, 30.9543f, 14.966f, 31.1698f, 14.7639f)
                curveTo(31.3853f, 14.5619f, 32.0947f, 15.4733f, 33.1722f, 15.7607f)
                curveTo(34.1621f, 16.0246f, 34.5641f, 15.5991f, 34.5371f, 15.2085f)
                curveTo(34.5101f, 14.8178f, 34.2811f, 14.9795f, 33.1631f, 14.8717f)
                curveTo(32.0452f, 14.764f, 31.2639f, 13.7133f, 31.2235f, 13.646f)
                curveTo(31.1831f, 13.5786f, 31.1965f, 13.2014f, 31.7623f, 13.5651f)
                curveTo(32.328f, 13.9288f, 33.3382f, 14.1443f, 33.9309f, 14.1174f)
                curveTo(34.5236f, 14.0904f, 34.6448f, 13.2957f, 34.362f, 13.161f)
                curveTo(34.0792f, 13.0263f, 34.0118f, 13.2284f, 33.3652f, 13.3631f)
                curveTo(32.7186f, 13.4977f, 31.5064f, 12.8378f, 31.21f, 12.5279f)
                curveTo(30.9136f, 12.218f, 31.237f, 12.0161f, 31.3851f, 12.0429f)
                curveTo(31.5333f, 12.0698f, 32.5031f, 12.6356f, 33.3652f, 12.6087f)
                curveTo(34.2273f, 12.5817f, 34.591f, 11.787f, 34.564f, 11.5445f)
                curveTo(34.5371f, 11.302f, 34.2946f, 11.1f, 33.9309f, 11.3425f)
                curveTo(33.5672f, 11.5849f, 33.0823f, 11.8677f, 32.2742f, 11.7331f)
                curveTo(31.466f, 11.5984f, 31.0888f, 11.0595f, 31.1966f, 10.8576f)
                curveTo(31.3044f, 10.6555f, 32.0856f, 11.0057f, 33.3249f, 10.8711f)
                curveTo(34.5641f, 10.7364f, 34.6179f, 9.6453f, 34.4967f, 9.4298f)
                curveTo(34.3755f, 9.2142f, 34.0792f, 9.2816f, 33.6616f, 9.6722f)
                curveTo(33.244f, 10.0629f, 32.4358f, 10.1437f, 31.8028f, 10.1437f)
                curveTo(31.1697f, 10.1437f, 31.1562f, 9.834f, 31.2505f, 9.6184f)
                curveTo(31.3449f, 9.4029f, 32.0183f, 9.4568f, 32.9612f, 9.1604f)
                curveTo(33.9041f, 8.8641f, 34.4428f, 8.1097f, 34.5911f, 7.6384f)
                curveTo(34.7393f, 7.167f, 34.3755f, 6.8032f, 34.2274f, 6.8032f)
                curveTo(34.0793f, 6.8032f, 34.1735f, 6.5877f, 33.527f, 7.5171f)
                curveTo(32.8804f, 8.4465f, 31.1833f, 8.8101f, 31.1833f, 8.8101f)
                curveTo(31.0621f, 8.5811f, 30.8735f, 8.5004f, 30.8735f, 8.5004f)
                curveTo(29.7286f, 8.7294f, 28.3475f, 9.7179f, 28.3475f, 9.7179f)
                curveTo(28.3475f, 9.7179f, 28.591f, 10.4988f, 28.2868f, 11.1074f)
                curveTo(27.8951f, 11.8908f, 26.5525f, 11.4014f, 26.9176f, 10.1642f)
                curveTo(27.2216f, 9.134f, 28.3983f, 8.5111f, 29.3923f, 8.3995f)
                curveTo(30.3863f, 8.288f, 30.437f, 7.9127f, 30.437f, 7.9127f)
                curveTo(30.437f, 7.9127f, 29.3932f, 8.2525f, 29.3746f, 7.786f)
                curveTo(29.5901f, 7.8298f, 29.9078f, 7.8488f, 30.4471f, 7.8011f)
                curveTo(31.2484f, 7.7302f, 31.6947f, 7.1621f, 31.3397f, 7.0404f)
                curveTo(30.9847f, 6.9187f, 31.1369f, 7.2839f, 30.5283f, 7.5679f)
                curveTo(30.0942f, 7.7704f, 29.6386f, 7.6792f, 29.4106f, 7.6099f)
                curveTo(29.6213f, 7.15f, 30.508f, 7.3852f, 30.508f, 7.3852f)
                curveTo(30.5181f, 6.584f, 29.4549f, 6.8706f, 29.4549f, 6.8706f)
                curveTo(28.9881f, 5.8647f, 26.2581f, 7.0322f, 26.2581f, 7.0322f)
                lineTo(26.9047f, 7.553f)
                curveTo(25.3242f, 8.433f, 25.0009f, 10.1032f, 25.0009f, 10.1032f)
                curveTo(25.0009f, 10.1032f, 24.6776f, 8.433f, 23.097f, 7.5529f)
                lineTo(23.7436f, 7.0321f)
                curveTo(23.7436f, 7.0321f, 21.0137f, 5.8646f, 20.5468f, 6.8705f)
                curveTo(20.5468f, 6.8705f, 19.4835f, 6.584f, 19.4937f, 7.3851f)
                curveTo(19.4937f, 7.3851f, 20.3805f, 7.1499f, 20.5912f, 7.6098f)
                curveTo(20.3632f, 7.6792f, 19.9076f, 7.7703f, 19.4735f, 7.5678f)
                curveTo(18.865f, 7.2837f, 19.0172f, 6.9187f, 18.6621f, 7.0403f)
                curveTo(18.3071f, 7.162f, 18.7534f, 7.73f, 19.5547f, 7.801f)
                curveTo(20.094f, 7.8487f, 20.4117f, 7.8298f, 20.6272f, 7.7859f)
                curveTo(20.6086f, 8.2524f, 19.5648f, 7.9126f, 19.5648f, 7.9126f)
                curveTo(19.5648f, 7.9126f, 19.6156f, 8.2878f, 20.6095f, 8.3994f)
                curveTo(21.6034f, 8.511f, 22.7802f, 9.1339f, 23.0842f, 10.1641f)
                curveTo(23.4492f, 11.4014f, 22.1067f, 11.8906f, 21.715f, 11.1073f)
                curveTo(21.4107f, 10.4988f, 21.6543f, 9.7178f, 21.6543f, 9.7178f)
                curveTo(21.6543f, 9.7178f, 20.2732f, 8.7293f, 19.1283f, 8.5003f)
                curveTo(19.1283f, 8.5003f, 18.9397f, 8.5811f, 18.8185f, 8.81f)
                curveTo(18.8185f, 8.81f, 17.1214f, 8.4464f, 16.4748f, 7.517f)
                curveTo(15.8282f, 6.5876f, 15.9226f, 6.8031f, 15.7744f, 6.8031f)
                curveTo(15.6263f, 6.8031f, 15.2626f, 7.1668f, 15.4107f, 7.6383f)
                curveTo(15.5589f, 8.1097f, 16.0976f, 8.864f, 17.0406f, 9.1603f)
                curveTo(17.9836f, 9.4566f, 18.657f, 9.4028f, 18.7513f, 9.6183f)
                curveTo(18.8456f, 9.8339f, 18.8321f, 10.1436f, 18.199f, 10.1436f)
                curveTo(17.5659f, 10.1436f, 16.7577f, 10.0628f, 16.3402f, 9.6721f)
                curveTo(15.9226f, 9.2815f, 15.6264f, 9.2141f, 15.5051f, 9.4297f)
                curveTo(15.3839f, 9.6452f, 15.4377f, 10.7362f, 16.6769f, 10.871f)
                curveTo(17.9162f, 11.0056f, 18.6974f, 10.6554f, 18.8052f, 10.8575f)
                curveTo(18.9129f, 11.0595f, 18.5357f, 11.5983f, 17.7276f, 11.733f)
                curveTo(16.9194f, 11.8676f, 16.4346f, 11.5848f, 16.0709f, 11.3424f)
                curveTo(15.7072f, 11.0999f, 15.4647f, 11.3019f, 15.4378f, 11.5444f)
                curveTo(15.4108f, 11.7869f, 15.7745f, 12.5816f, 16.6366f, 12.6086f)
                curveTo(17.4987f, 12.6355f, 18.4685f, 12.0698f, 18.6167f, 12.0428f)
                curveTo(18.7648f, 12.0159f, 19.0882f, 12.2179f, 18.7918f, 12.5277f)
                curveTo(18.4955f, 12.8375f, 17.2832f, 13.4975f, 16.6366f, 13.3629f)
                curveTo(15.99f, 13.2282f, 15.9227f, 13.0261f, 15.6398f, 13.1608f)
                curveTo(15.3569f, 13.2955f, 15.4782f, 14.0902f, 16.0709f, 14.1172f)
                curveTo(16.6636f, 14.1441f, 17.6738f, 13.9286f, 18.2395f, 13.5649f)
                curveTo(18.8053f, 13.2012f, 18.8187f, 13.5784f, 18.7783f, 13.6458f)
                curveTo(18.7379f, 13.7132f, 17.9566f, 14.7637f, 16.8387f, 14.8716f)
                curveTo(15.7207f, 14.9793f, 15.4917f, 14.8176f, 15.4647f, 15.2083f)
                curveTo(15.4378f, 15.5989f, 15.8398f, 16.0245f, 16.8297f, 15.7605f)
                curveTo(17.9072f, 15.4731f, 18.6168f, 14.5617f, 18.8322f, 14.7637f)
                curveTo(19.0477f, 14.9658f, 18.8592f, 15.4641f, 18.3877f, 15.8279f)
                curveTo(17.9162f, 16.1916f, 17.2606f, 16.874f, 16.1113f, 16.7124f)
                curveTo(15.1002f, 16.5703f, 15.4109f, 17.1749f, 15.4917f, 17.3095f)
                curveTo(15.5725f, 17.4442f, 16.2595f, 17.9964f, 17.2428f, 17.4172f)
                curveTo(18.2261f, 16.8381f, 18.7379f, 16.0838f, 18.8726f, 16.0298f)
                curveTo(19.0073f, 15.9759f, 19.2005f, 15.9759f, 18.967f, 16.5506f)
                curveTo(18.7335f, 17.1254f, 17.9432f, 18.1671f, 17.171f, 18.3645f)
                curveTo(16.3987f, 18.5622f, 15.7342f, 18.4184f, 15.5905f, 18.7777f)
                curveTo(15.4469f, 19.1369f, 16.3268f, 19.9989f, 17.4224f, 19.2626f)
                curveTo(18.4283f, 18.5866f, 19.6136f, 16.82f, 19.9369f, 16.838f)
                curveTo(20.2601f, 16.8558f, 20.2092f, 17.4549f, 20.0117f, 17.7602f)
                curveTo(19.814f, 18.0656f, 18.8413f, 18.5082f, 18.8952f, 18.8673f)
                curveTo(18.9491f, 19.2265f, 19.4518f, 19.3342f, 20.0087f, 18.7596f)
                curveTo(20.5654f, 18.1849f, 20.7091f, 17.0714f, 20.9427f, 17.0175f)
                curveTo(21.1761f, 16.9636f, 21.679f, 17.1613f, 21.3916f, 17.7001f)
                curveTo(21.1043f, 18.2388f, 20.4757f, 18.616f, 20.781f, 18.8674f)
                curveTo(21.0863f, 19.1188f, 21.5533f, 18.7238f, 21.8048f, 18.2209f)
                curveTo(22.0561f, 17.7179f, 22.2537f, 17.1074f, 22.4872f, 17.3229f)
                curveTo(22.7207f, 17.5384f, 22.5411f, 18.3645f, 22.0382f, 18.8854f)
                curveTo(21.5353f, 19.4062f, 19.8937f, 19.823f, 19.9369f, 20.0708f)
                curveTo(19.9626f, 20.2183f, 20.1388f, 20.4619f, 20.3633f, 20.7092f)
                lineTo(18.1993f, 21.3503f)
                lineTo(17.216f, 20.5961f)
                curveTo(17.216f, 20.5961f, 17.1083f, 20.0842f, 16.4752f, 20.1515f)
                curveTo(16.4752f, 20.1515f, 15.6939f, 19.8013f, 15.6131f, 20.421f)
                curveTo(15.6131f, 20.421f, 16.1788f, 20.3266f, 16.3135f, 20.5287f)
                curveTo(16.4481f, 20.7307f, 16.5155f, 20.8789f, 17.0139f, 20.8384f)
                lineTo(17.7007f, 21.4177f)
                lineTo(16.3267f, 21.2561f)
                curveTo(16.3267f, 21.2561f, 16.0438f, 20.7846f, 15.5725f, 21.081f)
                curveTo(15.5725f, 21.081f, 14.9798f, 20.8924f, 14.8316f, 21.4582f)
                curveTo(14.8316f, 21.4582f, 15.3435f, 21.3638f, 15.4782f, 21.4312f)
                curveTo(15.4782f, 21.4312f, 15.6803f, 21.8892f, 16.3268f, 21.6198f)
                lineTo(17.6873f, 21.6872f)
                lineTo(16.9195f, 22.0643f)
                curveTo(16.9195f, 22.0643f, 15.9497f, 21.7545f, 15.9901f, 22.5626f)
                curveTo(15.9901f, 22.5626f, 15.7072f, 22.8859f, 15.9632f, 23.2092f)
                curveTo(15.9632f, 23.2092f, 16.0171f, 22.859f, 16.2729f, 22.7109f)
                curveTo(16.2729f, 22.7109f, 16.7713f, 22.9129f, 17.0946f, 22.3203f)
                lineTo(18.0779f, 21.7276f)
                lineTo(19.0477f, 22.1991f)
                curveTo(19.0477f, 22.1991f, 19.0208f, 22.7244f, 19.7347f, 22.5088f)
                curveTo(19.7347f, 22.5088f, 19.9771f, 22.6705f, 20.0444f, 22.886f)
                curveTo(20.0444f, 22.886f, 20.3542f, 22.5493f, 19.8558f, 22.1586f)
                curveTo(19.8558f, 22.1586f, 19.7347f, 21.7006f, 19.142f, 21.8757f)
                lineTo(18.5897f, 21.5928f)
                lineTo(20.6332f, 20.9865f)
                curveTo(20.987f, 21.3234f, 21.3687f, 21.5997f, 21.5023f, 21.5534f)
                curveTo(21.766f, 21.4621f, 21.9689f, 21.1883f, 22.1513f, 20.8029f)
                curveTo(22.3339f, 20.4174f, 23.1554f, 19.0788f, 23.5611f, 19.089f)
                curveTo(23.9668f, 19.0992f, 24.1494f, 19.4236f, 24.0479f, 20.0018f)
                curveTo(23.9466f, 20.5798f, 23.1559f, 21.6873f, 22.644f, 21.9163f)
                curveTo(22.1322f, 22.1453f, 22.0513f, 22.253f, 21.5799f, 22.2126f)
                curveTo(21.1084f, 22.1721f, 20.9468f, 22.3742f, 21.0546f, 22.6436f)
                curveTo(21.1623f, 22.913f, 21.6927f, 23.0843f, 22.213f, 22.8861f)
                curveTo(22.7787f, 22.6706f, 22.9942f, 22.3743f, 23.2097f, 22.5494f)
                curveTo(23.4253f, 22.7245f, 22.9807f, 23.3576f, 22.3746f, 23.5461f)
                curveTo(21.7684f, 23.7347f, 21.3104f, 23.6135f, 21.4048f, 23.9907f)
                curveTo(21.4991f, 24.3678f, 22.1456f, 24.3948f, 22.5632f, 24.1523f)
                curveTo(22.9807f, 23.9098f, 23.4521f, 23.2364f, 23.5734f, 23.4519f)
                curveTo(23.6946f, 23.6674f, 23.6138f, 24.2197f, 23.2097f, 24.5161f)
                curveTo(22.8056f, 24.8124f, 22.346f, 24.638f, 22.2534f, 24.9067f)
                curveTo(22.1187f, 25.2973f, 22.5901f, 25.5533f, 22.9538f, 25.405f)
                curveTo(23.3175f, 25.2569f, 23.735f, 24.7989f, 23.9236f, 24.3948f)
                curveTo(24.1122f, 23.9907f, 24.4624f, 24.3409f, 24.3547f, 24.718f)
                curveTo(24.2469f, 25.0952f, 24.3367f, 25.4049f, 23.5643f, 25.9976f)
                lineTo(25.0011f, 28.2066f)
                lineTo(26.438f, 25.9975f)
                curveTo(25.6657f, 25.4048f, 25.7555f, 25.0951f, 25.6476f, 24.7179f)
                curveTo(25.5399f, 24.3408f, 25.8901f, 23.9906f, 26.0787f, 24.3947f)
                curveTo(26.2673f, 24.7988f, 26.6848f, 25.2568f, 27.0485f, 25.4049f)
                curveTo(27.4122f, 25.5531f, 27.8837f, 25.2972f, 27.7489f, 24.9066f)
                curveTo(27.6563f, 24.6379f, 27.1966f, 24.8123f, 26.7925f, 24.516f)
                curveTo(26.3884f, 24.2197f, 26.3076f, 23.6673f, 26.4289f, 23.4518f)
                curveTo(26.5501f, 23.2363f, 27.0216f, 23.9098f, 27.4391f, 24.1522f)
                curveTo(27.8567f, 24.3947f, 28.5033f, 24.3677f, 28.5975f, 23.9906f)
                curveTo(28.6918f, 23.6134f, 28.2339f, 23.7346f, 27.6277f, 23.546f)
                curveTo(27.0216f, 23.3575f, 26.577f, 22.7244f, 26.7925f, 22.5493f)
                curveTo(27.0081f, 22.3742f, 27.2236f, 22.6705f, 27.7893f, 22.886f)
                curveTo(28.3097f, 23.0842f, 28.84f, 22.9129f, 28.9477f, 22.6435f)
                curveTo(29.0554f, 22.3741f, 28.8938f, 22.172f, 28.4224f, 22.2125f)
                curveTo(27.9509f, 22.2529f, 27.8702f, 22.1451f, 27.3583f, 21.9162f)
                curveTo(26.8464f, 21.6873f, 26.0558f, 20.5798f, 25.9544f, 20.0017f)
                curveTo(25.8529f, 19.4236f, 26.0355f, 19.099f, 26.4412f, 19.0889f)
                curveTo(26.8468f, 19.0787f, 27.6683f, 20.4173f, 27.8509f, 20.8028f)
                curveTo(28.0335f, 21.1882f, 28.2363f, 21.4621f, 28.5f, 21.5534f)
                curveTo(28.6337f, 21.5997f, 29.0155f, 21.3234f, 29.3691f, 20.9865f)
                lineTo(31.4126f, 21.5928f)
                lineTo(30.8603f, 21.8757f)
                curveTo(30.2676f, 21.7006f, 30.1464f, 22.1586f, 30.1464f, 22.1586f)
                curveTo(29.6481f, 22.5493f, 29.9579f, 22.886f, 29.9579f, 22.886f)
                curveTo(30.0253f, 22.6705f, 30.2676f, 22.5088f, 30.2676f, 22.5088f)
                curveTo(30.9815f, 22.7244f, 30.9546f, 22.1991f, 30.9546f, 22.1991f)
                lineTo(31.9244f, 21.7276f)
                lineTo(32.9077f, 22.3203f)
                curveTo(33.2309f, 22.9129f, 33.7293f, 22.7109f, 33.7293f, 22.7109f)
                curveTo(33.9853f, 22.859f, 34.0391f, 23.2092f, 34.0391f, 23.2092f)
                curveTo(34.2951f, 22.886f, 34.0122f, 22.5626f, 34.0122f, 22.5626f)
                curveTo(34.0526f, 21.7545f, 33.0828f, 22.0644f, 33.0828f, 22.0644f)
                lineTo(32.3146f, 21.6875f)
                lineTo(33.6751f, 21.6201f)
                curveTo(34.3216f, 21.8895f, 34.5237f, 21.4315f, 34.5237f, 21.4315f)
                curveTo(34.6584f, 21.3641f, 35.1703f, 21.4585f, 35.1703f, 21.4585f)
                curveTo(35.022f, 20.8927f, 34.4293f, 21.0813f, 34.4293f, 21.0813f)
                close()
            }
        }
        .build()
        return _flagAl!!
    }

private var _flagAl: ImageVector? = null
