package com.eltonkola.components.icons.ekicon

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.PathFillType.Companion.NonZero
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeCap.Companion.Butt
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.StrokeJoin.Companion.Miter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.ImageVector.Builder
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp
import com.eltonkola.components.icons.EKIcon

public val EKIcon.LogoComplex: ImageVector
    get() {
        if (_logoComplex != null) {
            return _logoComplex!!
        }
        _logoComplex = Builder(name = "LogoComplex", defaultWidth = 373.0.dp, defaultHeight =
                71.0.dp, viewportWidth = 373.0f, viewportHeight = 71.0f).apply {
            path(fill = SolidColor(Color(0xFF301453)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(76.443f, 46.0f)
                verticalLineTo(11.091f)
                horizontalLineTo(99.148f)
                verticalLineTo(16.392f)
                horizontalLineTo(82.767f)
                verticalLineTo(25.869f)
                horizontalLineTo(97.972f)
                verticalLineTo(31.17f)
                horizontalLineTo(82.767f)
                verticalLineTo(40.699f)
                horizontalLineTo(99.284f)
                verticalLineTo(46.0f)
                horizontalLineTo(76.443f)
                close()
                moveTo(105.599f, 46.0f)
                verticalLineTo(11.091f)
                horizontalLineTo(111.923f)
                verticalLineTo(40.699f)
                horizontalLineTo(127.298f)
                verticalLineTo(46.0f)
                horizontalLineTo(105.599f)
                close()
                moveTo(126.8f, 16.392f)
                verticalLineTo(11.091f)
                horizontalLineTo(154.652f)
                verticalLineTo(16.392f)
                horizontalLineTo(143.862f)
                verticalLineTo(46.0f)
                horizontalLineTo(137.589f)
                verticalLineTo(16.392f)
                horizontalLineTo(126.8f)
                close()
                moveTo(189.668f, 28.545f)
                curveTo(189.668f, 32.307f, 188.963f, 35.528f, 187.554f, 38.21f)
                curveTo(186.156f, 40.881f, 184.247f, 42.926f, 181.827f, 44.347f)
                curveTo(179.418f, 45.767f, 176.685f, 46.477f, 173.628f, 46.477f)
                curveTo(170.571f, 46.477f, 167.832f, 45.767f, 165.412f, 44.347f)
                curveTo(163.003f, 42.915f, 161.094f, 40.864f, 159.685f, 38.193f)
                curveTo(158.287f, 35.511f, 157.588f, 32.296f, 157.588f, 28.545f)
                curveTo(157.588f, 24.784f, 158.287f, 21.568f, 159.685f, 18.898f)
                curveTo(161.094f, 16.216f, 163.003f, 14.165f, 165.412f, 12.744f)
                curveTo(167.832f, 11.324f, 170.571f, 10.614f, 173.628f, 10.614f)
                curveTo(176.685f, 10.614f, 179.418f, 11.324f, 181.827f, 12.744f)
                curveTo(184.247f, 14.165f, 186.156f, 16.216f, 187.554f, 18.898f)
                curveTo(188.963f, 21.568f, 189.668f, 24.784f, 189.668f, 28.545f)
                close()
                moveTo(183.31f, 28.545f)
                curveTo(183.31f, 25.898f, 182.895f, 23.665f, 182.065f, 21.847f)
                curveTo(181.247f, 20.017f, 180.111f, 18.636f, 178.656f, 17.705f)
                curveTo(177.202f, 16.761f, 175.526f, 16.29f, 173.628f, 16.29f)
                curveTo(171.73f, 16.29f, 170.054f, 16.761f, 168.599f, 17.705f)
                curveTo(167.145f, 18.636f, 166.003f, 20.017f, 165.173f, 21.847f)
                curveTo(164.355f, 23.665f, 163.946f, 25.898f, 163.946f, 28.545f)
                curveTo(163.946f, 31.193f, 164.355f, 33.432f, 165.173f, 35.261f)
                curveTo(166.003f, 37.079f, 167.145f, 38.46f, 168.599f, 39.403f)
                curveTo(170.054f, 40.335f, 171.73f, 40.801f, 173.628f, 40.801f)
                curveTo(175.526f, 40.801f, 177.202f, 40.335f, 178.656f, 39.403f)
                curveTo(180.111f, 38.46f, 181.247f, 37.079f, 182.065f, 35.261f)
                curveTo(182.895f, 33.432f, 183.31f, 31.193f, 183.31f, 28.545f)
                close()
                moveTo(224.334f, 11.091f)
                verticalLineTo(46.0f)
                horizontalLineTo(218.709f)
                lineTo(202.26f, 22.222f)
                horizontalLineTo(201.97f)
                verticalLineTo(46.0f)
                horizontalLineTo(195.646f)
                verticalLineTo(11.091f)
                horizontalLineTo(201.305f)
                lineTo(217.737f, 34.886f)
                horizontalLineTo(218.044f)
                verticalLineTo(11.091f)
                horizontalLineTo(224.334f)
                close()
                moveTo(243.131f, 46.0f)
                verticalLineTo(11.091f)
                horizontalLineTo(249.455f)
                verticalLineTo(27.131f)
                horizontalLineTo(249.881f)
                lineTo(263.5f, 11.091f)
                horizontalLineTo(271.222f)
                lineTo(257.722f, 26.756f)
                lineTo(271.341f, 46.0f)
                horizontalLineTo(263.739f)
                lineTo(253.324f, 31.034f)
                lineTo(249.455f, 35.602f)
                verticalLineTo(46.0f)
                horizontalLineTo(243.131f)
                close()
                moveTo(304.933f, 28.545f)
                curveTo(304.933f, 32.307f, 304.229f, 35.528f, 302.82f, 38.21f)
                curveTo(301.422f, 40.881f, 299.513f, 42.926f, 297.092f, 44.347f)
                curveTo(294.683f, 45.767f, 291.95f, 46.477f, 288.893f, 46.477f)
                curveTo(285.837f, 46.477f, 283.098f, 45.767f, 280.678f, 44.347f)
                curveTo(278.268f, 42.915f, 276.359f, 40.864f, 274.95f, 38.193f)
                curveTo(273.553f, 35.511f, 272.854f, 32.296f, 272.854f, 28.545f)
                curveTo(272.854f, 24.784f, 273.553f, 21.568f, 274.95f, 18.898f)
                curveTo(276.359f, 16.216f, 278.268f, 14.165f, 280.678f, 12.744f)
                curveTo(283.098f, 11.324f, 285.837f, 10.614f, 288.893f, 10.614f)
                curveTo(291.95f, 10.614f, 294.683f, 11.324f, 297.092f, 12.744f)
                curveTo(299.513f, 14.165f, 301.422f, 16.216f, 302.82f, 18.898f)
                curveTo(304.229f, 21.568f, 304.933f, 24.784f, 304.933f, 28.545f)
                close()
                moveTo(298.575f, 28.545f)
                curveTo(298.575f, 25.898f, 298.161f, 23.665f, 297.331f, 21.847f)
                curveTo(296.513f, 20.017f, 295.376f, 18.636f, 293.922f, 17.705f)
                curveTo(292.467f, 16.761f, 290.791f, 16.29f, 288.893f, 16.29f)
                curveTo(286.996f, 16.29f, 285.32f, 16.761f, 283.865f, 17.705f)
                curveTo(282.411f, 18.636f, 281.268f, 20.017f, 280.439f, 21.847f)
                curveTo(279.621f, 23.665f, 279.212f, 25.898f, 279.212f, 28.545f)
                curveTo(279.212f, 31.193f, 279.621f, 33.432f, 280.439f, 35.261f)
                curveTo(281.268f, 37.079f, 282.411f, 38.46f, 283.865f, 39.403f)
                curveTo(285.32f, 40.335f, 286.996f, 40.801f, 288.893f, 40.801f)
                curveTo(290.791f, 40.801f, 292.467f, 40.335f, 293.922f, 39.403f)
                curveTo(295.376f, 38.46f, 296.513f, 37.079f, 297.331f, 35.261f)
                curveTo(298.161f, 33.432f, 298.575f, 31.193f, 298.575f, 28.545f)
                close()
                moveTo(310.912f, 46.0f)
                verticalLineTo(11.091f)
                horizontalLineTo(317.236f)
                verticalLineTo(40.699f)
                horizontalLineTo(332.611f)
                verticalLineTo(46.0f)
                horizontalLineTo(310.912f)
                close()
                moveTo(343.895f, 46.0f)
                horizontalLineTo(337.145f)
                lineTo(349.435f, 11.091f)
                horizontalLineTo(357.241f)
                lineTo(369.548f, 46.0f)
                horizontalLineTo(362.798f)
                lineTo(353.474f, 18.25f)
                horizontalLineTo(353.202f)
                lineTo(343.895f, 46.0f)
                close()
                moveTo(344.116f, 32.313f)
                horizontalLineTo(362.526f)
                verticalLineTo(37.392f)
                horizontalLineTo(344.116f)
                verticalLineTo(32.313f)
                close()
            }
            path(fill = SolidColor(Color(0xFF301453)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(12.351f, 11.091f)
                curveTo(24.557f, 7.713f, 30.795f, 16.166f, 35.404f, 20.948f)
                lineTo(64.0f, 8.0f)
                verticalLineTo(17.448f)
                lineTo(34.736f, 30.878f)
                lineTo(31.023f, 27.061f)
                curveTo(27.511f, 23.413f, 22.295f, 16.444f, 14.015f, 20.385f)
                curveTo(3.751f, 27.061f, 11.348f, 34.682f, 15.576f, 39.35f)
                curveTo(19.304f, 42.939f, 23.983f, 47.806f, 31.023f, 55.096f)
                lineTo(34.715f, 58.847f)
                lineTo(64.0f, 42.294f)
                verticalLineTo(54.192f)
                lineTo(33.904f, 71.0f)
                curveTo(24.664f, 61.443f, 19.292f, 55.896f, 14.399f, 51.191f)
                curveTo(8.851f, 45.071f, 4.296f, 41.787f, 1.147f, 35.375f)
                curveTo(-2.374f, 25.394f, 2.449f, 14.71f, 12.351f, 11.091f)
                close()
            }
            path(fill = SolidColor(Color(0xFF301453)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(64.0f, 23.397f)
                lineTo(34.723f, 38.095f)
                verticalLineTo(47.893f)
                lineTo(64.0f, 33.196f)
                verticalLineTo(23.397f)
                close()
            }
            path(fill = SolidColor(Color(0xFF301453)), stroke = null, strokeLineWidth = 0.0f,
                    strokeLineCap = Butt, strokeLineJoin = Miter, strokeLineMiter = 4.0f,
                    pathFillType = NonZero) {
                moveTo(79.832f, 67.173f)
                curveTo(79.061f, 67.173f, 78.371f, 66.974f, 77.761f, 66.578f)
                curveTo(77.152f, 66.182f, 76.671f, 65.606f, 76.317f, 64.852f)
                curveTo(75.963f, 64.098f, 75.786f, 63.182f, 75.786f, 62.104f)
                curveTo(75.786f, 61.013f, 75.965f, 60.092f, 76.323f, 59.342f)
                curveTo(76.685f, 58.588f, 77.173f, 58.019f, 77.787f, 57.636f)
                curveTo(78.401f, 57.248f, 79.085f, 57.054f, 79.839f, 57.054f)
                curveTo(80.414f, 57.054f, 80.887f, 57.152f, 81.258f, 57.348f)
                curveTo(81.629f, 57.54f, 81.923f, 57.772f, 82.14f, 58.045f)
                curveTo(82.357f, 58.313f, 82.526f, 58.567f, 82.645f, 58.805f)
                horizontalLineTo(82.741f)
                verticalLineTo(53.909f)
                horizontalLineTo(85.061f)
                verticalLineTo(67.0f)
                horizontalLineTo(82.785f)
                verticalLineTo(65.453f)
                horizontalLineTo(82.645f)
                curveTo(82.526f, 65.692f, 82.353f, 65.945f, 82.127f, 66.214f)
                curveTo(81.901f, 66.478f, 81.603f, 66.704f, 81.232f, 66.891f)
                curveTo(80.862f, 67.079f, 80.395f, 67.173f, 79.832f, 67.173f)
                close()
                moveTo(80.478f, 65.274f)
                curveTo(80.968f, 65.274f, 81.386f, 65.142f, 81.731f, 64.878f)
                curveTo(82.076f, 64.609f, 82.338f, 64.237f, 82.517f, 63.759f)
                curveTo(82.696f, 63.282f, 82.785f, 62.726f, 82.785f, 62.091f)
                curveTo(82.785f, 61.456f, 82.696f, 60.904f, 82.517f, 60.435f)
                curveTo(82.342f, 59.967f, 82.082f, 59.602f, 81.737f, 59.342f)
                curveTo(81.396f, 59.082f, 80.977f, 58.952f, 80.478f, 58.952f)
                curveTo(79.962f, 58.952f, 79.532f, 59.087f, 79.187f, 59.355f)
                curveTo(78.842f, 59.624f, 78.582f, 59.994f, 78.407f, 60.467f)
                curveTo(78.232f, 60.94f, 78.145f, 61.481f, 78.145f, 62.091f)
                curveTo(78.145f, 62.704f, 78.232f, 63.252f, 78.407f, 63.734f)
                curveTo(78.586f, 64.211f, 78.848f, 64.588f, 79.193f, 64.865f)
                curveTo(79.543f, 65.138f, 79.971f, 65.274f, 80.478f, 65.274f)
                close()
                moveTo(92.233f, 67.192f)
                curveTo(91.249f, 67.192f, 90.399f, 66.987f, 89.683f, 66.578f)
                curveTo(88.971f, 66.165f, 88.424f, 65.581f, 88.04f, 64.827f)
                curveTo(87.657f, 64.068f, 87.465f, 63.175f, 87.465f, 62.148f)
                curveTo(87.465f, 61.139f, 87.657f, 60.252f, 88.04f, 59.489f)
                curveTo(88.428f, 58.722f, 88.969f, 58.126f, 89.664f, 57.7f)
                curveTo(90.358f, 57.269f, 91.174f, 57.054f, 92.112f, 57.054f)
                curveTo(92.717f, 57.054f, 93.288f, 57.152f, 93.825f, 57.348f)
                curveTo(94.366f, 57.54f, 94.843f, 57.838f, 95.257f, 58.243f)
                curveTo(95.674f, 58.648f, 96.003f, 59.163f, 96.241f, 59.79f)
                curveTo(96.48f, 60.412f, 96.599f, 61.153f, 96.599f, 62.014f)
                verticalLineTo(62.724f)
                horizontalLineTo(88.552f)
                verticalLineTo(61.164f)
                horizontalLineTo(94.381f)
                curveTo(94.377f, 60.721f, 94.281f, 60.327f, 94.093f, 59.981f)
                curveTo(93.906f, 59.632f, 93.644f, 59.357f, 93.307f, 59.157f)
                curveTo(92.975f, 58.957f, 92.587f, 58.856f, 92.144f, 58.856f)
                curveTo(91.671f, 58.856f, 91.255f, 58.972f, 90.897f, 59.202f)
                curveTo(90.539f, 59.428f, 90.26f, 59.726f, 90.06f, 60.097f)
                curveTo(89.864f, 60.463f, 89.764f, 60.866f, 89.76f, 61.305f)
                verticalLineTo(62.666f)
                curveTo(89.76f, 63.237f, 89.864f, 63.727f, 90.073f, 64.136f)
                curveTo(90.282f, 64.541f, 90.574f, 64.852f, 90.949f, 65.07f)
                curveTo(91.324f, 65.283f, 91.762f, 65.389f, 92.265f, 65.389f)
                curveTo(92.602f, 65.389f, 92.907f, 65.342f, 93.179f, 65.249f)
                curveTo(93.452f, 65.151f, 93.689f, 65.008f, 93.889f, 64.82f)
                curveTo(94.089f, 64.633f, 94.241f, 64.401f, 94.343f, 64.124f)
                lineTo(96.503f, 64.367f)
                curveTo(96.367f, 64.938f, 96.107f, 65.436f, 95.724f, 65.862f)
                curveTo(95.344f, 66.284f, 94.858f, 66.612f, 94.266f, 66.847f)
                curveTo(93.674f, 67.077f, 92.996f, 67.192f, 92.233f, 67.192f)
                close()
                moveTo(106.677f, 59.777f)
                lineTo(104.567f, 60.007f)
                curveTo(104.508f, 59.794f, 104.403f, 59.594f, 104.254f, 59.406f)
                curveTo(104.109f, 59.219f, 103.913f, 59.068f, 103.666f, 58.952f)
                curveTo(103.419f, 58.837f, 103.116f, 58.78f, 102.758f, 58.78f)
                curveTo(102.277f, 58.78f, 101.872f, 58.884f, 101.544f, 59.093f)
                curveTo(101.22f, 59.302f, 101.06f, 59.572f, 101.064f, 59.905f)
                curveTo(101.06f, 60.19f, 101.165f, 60.423f, 101.378f, 60.602f)
                curveTo(101.595f, 60.78f, 101.953f, 60.928f, 102.452f, 61.043f)
                lineTo(104.126f, 61.401f)
                curveTo(105.055f, 61.601f, 105.746f, 61.918f, 106.197f, 62.353f)
                curveTo(106.653f, 62.788f, 106.883f, 63.356f, 106.888f, 64.06f)
                curveTo(106.883f, 64.678f, 106.702f, 65.223f, 106.344f, 65.696f)
                curveTo(105.991f, 66.165f, 105.498f, 66.531f, 104.868f, 66.796f)
                curveTo(104.237f, 67.06f, 103.513f, 67.192f, 102.694f, 67.192f)
                curveTo(101.493f, 67.192f, 100.525f, 66.94f, 99.792f, 66.438f)
                curveTo(99.06f, 65.93f, 98.623f, 65.225f, 98.482f, 64.322f)
                lineTo(100.738f, 64.104f)
                curveTo(100.841f, 64.548f, 101.058f, 64.882f, 101.39f, 65.108f)
                curveTo(101.723f, 65.334f, 102.155f, 65.447f, 102.688f, 65.447f)
                curveTo(103.238f, 65.447f, 103.679f, 65.334f, 104.011f, 65.108f)
                curveTo(104.348f, 64.882f, 104.516f, 64.603f, 104.516f, 64.271f)
                curveTo(104.516f, 63.989f, 104.407f, 63.757f, 104.19f, 63.574f)
                curveTo(103.977f, 63.391f, 103.645f, 63.25f, 103.193f, 63.152f)
                lineTo(101.518f, 62.8f)
                curveTo(100.577f, 62.604f, 99.88f, 62.274f, 99.428f, 61.81f)
                curveTo(98.976f, 61.341f, 98.753f, 60.749f, 98.757f, 60.033f)
                curveTo(98.753f, 59.428f, 98.917f, 58.903f, 99.249f, 58.46f)
                curveTo(99.586f, 58.013f, 100.052f, 57.668f, 100.649f, 57.425f)
                curveTo(101.25f, 57.178f, 101.942f, 57.054f, 102.726f, 57.054f)
                curveTo(103.877f, 57.054f, 104.782f, 57.299f, 105.443f, 57.789f)
                curveTo(106.108f, 58.279f, 106.519f, 58.942f, 106.677f, 59.777f)
                close()
                moveTo(109.156f, 67.0f)
                verticalLineTo(57.182f)
                horizontalLineTo(111.47f)
                verticalLineTo(67.0f)
                horizontalLineTo(109.156f)
                close()
                moveTo(110.319f, 55.788f)
                curveTo(109.953f, 55.788f, 109.637f, 55.667f, 109.373f, 55.424f)
                curveTo(109.109f, 55.177f, 108.977f, 54.881f, 108.977f, 54.535f)
                curveTo(108.977f, 54.186f, 109.109f, 53.89f, 109.373f, 53.647f)
                curveTo(109.637f, 53.4f, 109.953f, 53.276f, 110.319f, 53.276f)
                curveTo(110.69f, 53.276f, 111.005f, 53.4f, 111.265f, 53.647f)
                curveTo(111.529f, 53.89f, 111.661f, 54.186f, 111.661f, 54.535f)
                curveTo(111.661f, 54.881f, 111.529f, 55.177f, 111.265f, 55.424f)
                curveTo(111.005f, 55.667f, 110.69f, 55.788f, 110.319f, 55.788f)
                close()
                moveTo(118.479f, 70.886f)
                curveTo(117.648f, 70.886f, 116.934f, 70.773f, 116.338f, 70.548f)
                curveTo(115.741f, 70.326f, 115.262f, 70.028f, 114.899f, 69.653f)
                curveTo(114.537f, 69.278f, 114.286f, 68.862f, 114.145f, 68.406f)
                lineTo(116.229f, 67.901f)
                curveTo(116.323f, 68.093f, 116.459f, 68.283f, 116.638f, 68.47f)
                curveTo(116.817f, 68.662f, 117.058f, 68.82f, 117.36f, 68.943f)
                curveTo(117.667f, 69.071f, 118.053f, 69.135f, 118.517f, 69.135f)
                curveTo(119.173f, 69.135f, 119.717f, 68.975f, 120.147f, 68.656f)
                curveTo(120.578f, 68.34f, 120.793f, 67.82f, 120.793f, 67.096f)
                verticalLineTo(65.236f)
                horizontalLineTo(120.678f)
                curveTo(120.558f, 65.474f, 120.384f, 65.719f, 120.154f, 65.971f)
                curveTo(119.928f, 66.222f, 119.627f, 66.433f, 119.252f, 66.604f)
                curveTo(118.882f, 66.774f, 118.415f, 66.859f, 117.852f, 66.859f)
                curveTo(117.098f, 66.859f, 116.414f, 66.683f, 115.801f, 66.329f)
                curveTo(115.191f, 65.971f, 114.705f, 65.438f, 114.343f, 64.731f)
                curveTo(113.985f, 64.019f, 113.806f, 63.129f, 113.806f, 62.059f)
                curveTo(113.806f, 60.981f, 113.985f, 60.071f, 114.343f, 59.329f)
                curveTo(114.705f, 58.584f, 115.193f, 58.019f, 115.807f, 57.636f)
                curveTo(116.421f, 57.248f, 117.105f, 57.054f, 117.859f, 57.054f)
                curveTo(118.434f, 57.054f, 118.907f, 57.152f, 119.278f, 57.348f)
                curveTo(119.653f, 57.54f, 119.951f, 57.772f, 120.173f, 58.045f)
                curveTo(120.394f, 58.313f, 120.563f, 58.567f, 120.678f, 58.805f)
                horizontalLineTo(120.806f)
                verticalLineTo(57.182f)
                horizontalLineTo(123.088f)
                verticalLineTo(67.16f)
                curveTo(123.088f, 67.999f, 122.887f, 68.694f, 122.487f, 69.244f)
                curveTo(122.086f, 69.793f, 121.539f, 70.204f, 120.844f, 70.477f)
                curveTo(120.149f, 70.75f, 119.361f, 70.886f, 118.479f, 70.886f)
                close()
                moveTo(118.498f, 65.044f)
                curveTo(118.988f, 65.044f, 119.406f, 64.925f, 119.751f, 64.686f)
                curveTo(120.096f, 64.447f, 120.358f, 64.104f, 120.537f, 63.657f)
                curveTo(120.716f, 63.209f, 120.806f, 62.673f, 120.806f, 62.046f)
                curveTo(120.806f, 61.428f, 120.716f, 60.887f, 120.537f, 60.423f)
                curveTo(120.362f, 59.958f, 120.102f, 59.598f, 119.757f, 59.342f)
                curveTo(119.416f, 59.082f, 118.997f, 58.952f, 118.498f, 58.952f)
                curveTo(117.982f, 58.952f, 117.552f, 59.087f, 117.207f, 59.355f)
                curveTo(116.862f, 59.624f, 116.602f, 59.992f, 116.427f, 60.461f)
                curveTo(116.252f, 60.925f, 116.165f, 61.454f, 116.165f, 62.046f)
                curveTo(116.165f, 62.647f, 116.252f, 63.173f, 116.427f, 63.625f)
                curveTo(116.606f, 64.072f, 116.868f, 64.422f, 117.213f, 64.673f)
                curveTo(117.563f, 64.921f, 117.991f, 65.044f, 118.498f, 65.044f)
                close()
                moveTo(128.133f, 61.247f)
                verticalLineTo(67.0f)
                horizontalLineTo(125.819f)
                verticalLineTo(57.182f)
                horizontalLineTo(128.031f)
                verticalLineTo(58.85f)
                horizontalLineTo(128.146f)
                curveTo(128.372f, 58.3f, 128.732f, 57.864f, 129.226f, 57.54f)
                curveTo(129.725f, 57.216f, 130.34f, 57.054f, 131.073f, 57.054f)
                curveTo(131.751f, 57.054f, 132.341f, 57.199f, 132.844f, 57.489f)
                curveTo(133.351f, 57.778f, 133.743f, 58.198f, 134.02f, 58.748f)
                curveTo(134.301f, 59.298f, 134.44f, 59.965f, 134.435f, 60.749f)
                verticalLineTo(67.0f)
                horizontalLineTo(132.122f)
                verticalLineTo(61.106f)
                curveTo(132.122f, 60.45f, 131.951f, 59.937f, 131.61f, 59.566f)
                curveTo(131.274f, 59.195f, 130.807f, 59.01f, 130.21f, 59.01f)
                curveTo(129.806f, 59.01f, 129.445f, 59.099f, 129.13f, 59.278f)
                curveTo(128.819f, 59.453f, 128.574f, 59.707f, 128.395f, 60.039f)
                curveTo(128.22f, 60.371f, 128.133f, 60.774f, 128.133f, 61.247f)
                close()
                moveTo(137.148f, 67.0f)
                verticalLineTo(57.182f)
                horizontalLineTo(139.462f)
                verticalLineTo(67.0f)
                horizontalLineTo(137.148f)
                close()
                moveTo(138.311f, 55.788f)
                curveTo(137.945f, 55.788f, 137.629f, 55.667f, 137.365f, 55.424f)
                curveTo(137.101f, 55.177f, 136.969f, 54.881f, 136.969f, 54.535f)
                curveTo(136.969f, 54.186f, 137.101f, 53.89f, 137.365f, 53.647f)
                curveTo(137.629f, 53.4f, 137.945f, 53.276f, 138.311f, 53.276f)
                curveTo(138.682f, 53.276f, 138.997f, 53.4f, 139.257f, 53.647f)
                curveTo(139.521f, 53.89f, 139.653f, 54.186f, 139.653f, 54.535f)
                curveTo(139.653f, 54.881f, 139.521f, 55.177f, 139.257f, 55.424f)
                curveTo(138.997f, 55.667f, 138.682f, 55.788f, 138.311f, 55.788f)
                close()
                moveTo(144.515f, 61.247f)
                verticalLineTo(67.0f)
                horizontalLineTo(142.201f)
                verticalLineTo(57.182f)
                horizontalLineTo(144.413f)
                verticalLineTo(58.85f)
                horizontalLineTo(144.528f)
                curveTo(144.754f, 58.3f, 145.114f, 57.864f, 145.608f, 57.54f)
                curveTo(146.107f, 57.216f, 146.722f, 57.054f, 147.455f, 57.054f)
                curveTo(148.133f, 57.054f, 148.723f, 57.199f, 149.226f, 57.489f)
                curveTo(149.733f, 57.778f, 150.125f, 58.198f, 150.402f, 58.748f)
                curveTo(150.683f, 59.298f, 150.822f, 59.965f, 150.818f, 60.749f)
                verticalLineTo(67.0f)
                horizontalLineTo(148.504f)
                verticalLineTo(61.106f)
                curveTo(148.504f, 60.45f, 148.333f, 59.937f, 147.992f, 59.566f)
                curveTo(147.656f, 59.195f, 147.189f, 59.01f, 146.592f, 59.01f)
                curveTo(146.188f, 59.01f, 145.828f, 59.099f, 145.512f, 59.278f)
                curveTo(145.201f, 59.453f, 144.956f, 59.707f, 144.777f, 60.039f)
                curveTo(144.602f, 60.371f, 144.515f, 60.774f, 144.515f, 61.247f)
                close()
                moveTo(157.8f, 70.886f)
                curveTo(156.969f, 70.886f, 156.255f, 70.773f, 155.658f, 70.548f)
                curveTo(155.062f, 70.326f, 154.582f, 70.028f, 154.22f, 69.653f)
                curveTo(153.858f, 69.278f, 153.607f, 68.862f, 153.466f, 68.406f)
                lineTo(155.55f, 67.901f)
                curveTo(155.643f, 68.093f, 155.78f, 68.283f, 155.959f, 68.47f)
                curveTo(156.138f, 68.662f, 156.379f, 68.82f, 156.681f, 68.943f)
                curveTo(156.988f, 69.071f, 157.374f, 69.135f, 157.838f, 69.135f)
                curveTo(158.494f, 69.135f, 159.038f, 68.975f, 159.468f, 68.656f)
                curveTo(159.898f, 68.34f, 160.114f, 67.82f, 160.114f, 67.096f)
                verticalLineTo(65.236f)
                horizontalLineTo(159.999f)
                curveTo(159.879f, 65.474f, 159.705f, 65.719f, 159.474f, 65.971f)
                curveTo(159.249f, 66.222f, 158.948f, 66.433f, 158.573f, 66.604f)
                curveTo(158.202f, 66.774f, 157.736f, 66.859f, 157.173f, 66.859f)
                curveTo(156.419f, 66.859f, 155.735f, 66.683f, 155.121f, 66.329f)
                curveTo(154.512f, 65.971f, 154.026f, 65.438f, 153.664f, 64.731f)
                curveTo(153.306f, 64.019f, 153.127f, 63.129f, 153.127f, 62.059f)
                curveTo(153.127f, 60.981f, 153.306f, 60.071f, 153.664f, 59.329f)
                curveTo(154.026f, 58.584f, 154.514f, 58.019f, 155.128f, 57.636f)
                curveTo(155.742f, 57.248f, 156.425f, 57.054f, 157.18f, 57.054f)
                curveTo(157.755f, 57.054f, 158.228f, 57.152f, 158.599f, 57.348f)
                curveTo(158.974f, 57.54f, 159.272f, 57.772f, 159.494f, 58.045f)
                curveTo(159.715f, 58.313f, 159.884f, 58.567f, 159.999f, 58.805f)
                horizontalLineTo(160.126f)
                verticalLineTo(57.182f)
                horizontalLineTo(162.408f)
                verticalLineTo(67.16f)
                curveTo(162.408f, 67.999f, 162.208f, 68.694f, 161.808f, 69.244f)
                curveTo(161.407f, 69.793f, 160.859f, 70.204f, 160.165f, 70.477f)
                curveTo(159.47f, 70.75f, 158.682f, 70.886f, 157.8f, 70.886f)
                close()
                moveTo(157.819f, 65.044f)
                curveTo(158.309f, 65.044f, 158.727f, 64.925f, 159.072f, 64.686f)
                curveTo(159.417f, 64.447f, 159.679f, 64.104f, 159.858f, 63.657f)
                curveTo(160.037f, 63.209f, 160.126f, 62.673f, 160.126f, 62.046f)
                curveTo(160.126f, 61.428f, 160.037f, 60.887f, 159.858f, 60.423f)
                curveTo(159.683f, 59.958f, 159.423f, 59.598f, 159.078f, 59.342f)
                curveTo(158.737f, 59.082f, 158.317f, 58.952f, 157.819f, 58.952f)
                curveTo(157.303f, 58.952f, 156.873f, 59.087f, 156.528f, 59.355f)
                curveTo(156.183f, 59.624f, 155.923f, 59.992f, 155.748f, 60.461f)
                curveTo(155.573f, 60.925f, 155.486f, 61.454f, 155.486f, 62.046f)
                curveTo(155.486f, 62.647f, 155.573f, 63.173f, 155.748f, 63.625f)
                curveTo(155.927f, 64.072f, 156.189f, 64.422f, 156.534f, 64.673f)
                curveTo(156.884f, 64.921f, 157.312f, 65.044f, 157.819f, 65.044f)
                close()
                moveTo(172.79f, 67.198f)
                curveTo(172.168f, 67.198f, 171.607f, 67.087f, 171.109f, 66.866f)
                curveTo(170.615f, 66.64f, 170.223f, 66.308f, 169.933f, 65.869f)
                curveTo(169.647f, 65.43f, 169.504f, 64.888f, 169.504f, 64.245f)
                curveTo(169.504f, 63.691f, 169.607f, 63.233f, 169.811f, 62.871f)
                curveTo(170.016f, 62.508f, 170.295f, 62.219f, 170.649f, 62.001f)
                curveTo(171.002f, 61.784f, 171.401f, 61.62f, 171.844f, 61.509f)
                curveTo(172.291f, 61.394f, 172.754f, 61.311f, 173.231f, 61.26f)
                curveTo(173.806f, 61.2f, 174.273f, 61.147f, 174.631f, 61.1f)
                curveTo(174.989f, 61.049f, 175.249f, 60.972f, 175.411f, 60.87f)
                curveTo(175.577f, 60.764f, 175.66f, 60.599f, 175.66f, 60.378f)
                verticalLineTo(60.34f)
                curveTo(175.66f, 59.858f, 175.517f, 59.485f, 175.232f, 59.221f)
                curveTo(174.946f, 58.957f, 174.535f, 58.825f, 173.998f, 58.825f)
                curveTo(173.431f, 58.825f, 172.982f, 58.948f, 172.649f, 59.195f)
                curveTo(172.321f, 59.443f, 172.1f, 59.734f, 171.985f, 60.071f)
                lineTo(169.824f, 59.764f)
                curveTo(169.995f, 59.168f, 170.276f, 58.669f, 170.668f, 58.269f)
                curveTo(171.06f, 57.864f, 171.539f, 57.561f, 172.106f, 57.361f)
                curveTo(172.673f, 57.156f, 173.299f, 57.054f, 173.985f, 57.054f)
                curveTo(174.458f, 57.054f, 174.929f, 57.109f, 175.398f, 57.22f)
                curveTo(175.867f, 57.331f, 176.295f, 57.514f, 176.683f, 57.77f)
                curveTo(177.071f, 58.021f, 177.382f, 58.364f, 177.616f, 58.799f)
                curveTo(177.855f, 59.234f, 177.974f, 59.777f, 177.974f, 60.429f)
                verticalLineTo(67.0f)
                horizontalLineTo(175.75f)
                verticalLineTo(65.651f)
                horizontalLineTo(175.673f)
                curveTo(175.532f, 65.924f, 175.334f, 66.18f, 175.078f, 66.418f)
                curveTo(174.827f, 66.653f, 174.509f, 66.842f, 174.126f, 66.987f)
                curveTo(173.747f, 67.128f, 173.301f, 67.198f, 172.79f, 67.198f)
                close()
                moveTo(173.391f, 65.498f)
                curveTo(173.855f, 65.498f, 174.258f, 65.406f, 174.599f, 65.223f)
                curveTo(174.94f, 65.035f, 175.202f, 64.788f, 175.385f, 64.481f)
                curveTo(175.573f, 64.175f, 175.666f, 63.84f, 175.666f, 63.478f)
                verticalLineTo(62.321f)
                curveTo(175.594f, 62.381f, 175.47f, 62.436f, 175.296f, 62.487f)
                curveTo(175.125f, 62.538f, 174.933f, 62.583f, 174.72f, 62.621f)
                curveTo(174.507f, 62.66f, 174.296f, 62.694f, 174.088f, 62.724f)
                curveTo(173.879f, 62.754f, 173.698f, 62.779f, 173.544f, 62.8f)
                curveTo(173.199f, 62.847f, 172.89f, 62.924f, 172.617f, 63.03f)
                curveTo(172.345f, 63.137f, 172.129f, 63.286f, 171.972f, 63.478f)
                curveTo(171.814f, 63.666f, 171.735f, 63.908f, 171.735f, 64.207f)
                curveTo(171.735f, 64.633f, 171.891f, 64.954f, 172.202f, 65.172f)
                curveTo(172.513f, 65.389f, 172.909f, 65.498f, 173.391f, 65.498f)
                close()
                moveTo(182.975f, 61.247f)
                verticalLineTo(67.0f)
                horizontalLineTo(180.661f)
                verticalLineTo(57.182f)
                horizontalLineTo(182.872f)
                verticalLineTo(58.85f)
                horizontalLineTo(182.987f)
                curveTo(183.213f, 58.3f, 183.573f, 57.864f, 184.068f, 57.54f)
                curveTo(184.566f, 57.216f, 185.182f, 57.054f, 185.915f, 57.054f)
                curveTo(186.592f, 57.054f, 187.183f, 57.199f, 187.685f, 57.489f)
                curveTo(188.193f, 57.778f, 188.585f, 58.198f, 188.862f, 58.748f)
                curveTo(189.143f, 59.298f, 189.281f, 59.965f, 189.277f, 60.749f)
                verticalLineTo(67.0f)
                horizontalLineTo(186.963f)
                verticalLineTo(61.106f)
                curveTo(186.963f, 60.45f, 186.793f, 59.937f, 186.452f, 59.566f)
                curveTo(186.115f, 59.195f, 185.649f, 59.01f, 185.052f, 59.01f)
                curveTo(184.647f, 59.01f, 184.287f, 59.099f, 183.972f, 59.278f)
                curveTo(183.661f, 59.453f, 183.416f, 59.707f, 183.237f, 60.039f)
                curveTo(183.062f, 60.371f, 182.975f, 60.774f, 182.975f, 61.247f)
                close()
                moveTo(195.633f, 67.173f)
                curveTo(194.862f, 67.173f, 194.171f, 66.974f, 193.562f, 66.578f)
                curveTo(192.952f, 66.182f, 192.471f, 65.606f, 192.117f, 64.852f)
                curveTo(191.764f, 64.098f, 191.587f, 63.182f, 191.587f, 62.104f)
                curveTo(191.587f, 61.013f, 191.766f, 60.092f, 192.124f, 59.342f)
                curveTo(192.486f, 58.588f, 192.974f, 58.019f, 193.587f, 57.636f)
                curveTo(194.201f, 57.248f, 194.885f, 57.054f, 195.639f, 57.054f)
                curveTo(196.215f, 57.054f, 196.688f, 57.152f, 197.058f, 57.348f)
                curveTo(197.429f, 57.54f, 197.723f, 57.772f, 197.94f, 58.045f)
                curveTo(198.158f, 58.313f, 198.326f, 58.567f, 198.445f, 58.805f)
                horizontalLineTo(198.541f)
                verticalLineTo(53.909f)
                horizontalLineTo(200.862f)
                verticalLineTo(67.0f)
                horizontalLineTo(198.586f)
                verticalLineTo(65.453f)
                horizontalLineTo(198.445f)
                curveTo(198.326f, 65.692f, 198.153f, 65.945f, 197.928f, 66.214f)
                curveTo(197.702f, 66.478f, 197.403f, 66.704f, 197.033f, 66.891f)
                curveTo(196.662f, 67.079f, 196.195f, 67.173f, 195.633f, 67.173f)
                close()
                moveTo(196.278f, 65.274f)
                curveTo(196.769f, 65.274f, 197.186f, 65.142f, 197.531f, 64.878f)
                curveTo(197.876f, 64.609f, 198.139f, 64.237f, 198.318f, 63.759f)
                curveTo(198.496f, 63.282f, 198.586f, 62.726f, 198.586f, 62.091f)
                curveTo(198.586f, 61.456f, 198.496f, 60.904f, 198.318f, 60.435f)
                curveTo(198.143f, 59.967f, 197.883f, 59.602f, 197.538f, 59.342f)
                curveTo(197.197f, 59.082f, 196.777f, 58.952f, 196.278f, 58.952f)
                curveTo(195.763f, 58.952f, 195.332f, 59.087f, 194.987f, 59.355f)
                curveTo(194.642f, 59.624f, 194.382f, 59.994f, 194.207f, 60.467f)
                curveTo(194.033f, 60.94f, 193.945f, 61.481f, 193.945f, 62.091f)
                curveTo(193.945f, 62.704f, 194.033f, 63.252f, 194.207f, 63.734f)
                curveTo(194.386f, 64.211f, 194.648f, 64.588f, 194.994f, 64.865f)
                curveTo(195.343f, 65.138f, 195.771f, 65.274f, 196.278f, 65.274f)
                close()
                moveTo(212.795f, 67.192f)
                curveTo(211.815f, 67.192f, 210.973f, 66.977f, 210.27f, 66.546f)
                curveTo(209.571f, 66.116f, 209.032f, 65.521f, 208.653f, 64.763f)
                curveTo(208.278f, 64.0f, 208.09f, 63.122f, 208.09f, 62.129f)
                curveTo(208.09f, 61.132f, 208.282f, 60.252f, 208.666f, 59.489f)
                curveTo(209.049f, 58.722f, 209.59f, 58.126f, 210.289f, 57.7f)
                curveTo(210.992f, 57.269f, 211.823f, 57.054f, 212.782f, 57.054f)
                curveTo(213.579f, 57.054f, 214.284f, 57.201f, 214.898f, 57.495f)
                curveTo(215.516f, 57.785f, 216.008f, 58.196f, 216.374f, 58.729f)
                curveTo(216.741f, 59.257f, 216.95f, 59.875f, 217.001f, 60.582f)
                horizontalLineTo(214.789f)
                curveTo(214.7f, 60.109f, 214.487f, 59.715f, 214.15f, 59.4f)
                curveTo(213.818f, 59.08f, 213.372f, 58.921f, 212.814f, 58.921f)
                curveTo(212.341f, 58.921f, 211.925f, 59.048f, 211.568f, 59.304f)
                curveTo(211.21f, 59.555f, 210.93f, 59.918f, 210.73f, 60.391f)
                curveTo(210.534f, 60.864f, 210.436f, 61.43f, 210.436f, 62.091f)
                curveTo(210.436f, 62.76f, 210.534f, 63.335f, 210.73f, 63.817f)
                curveTo(210.926f, 64.294f, 211.201f, 64.663f, 211.555f, 64.923f)
                curveTo(211.913f, 65.178f, 212.332f, 65.306f, 212.814f, 65.306f)
                curveTo(213.155f, 65.306f, 213.46f, 65.242f, 213.728f, 65.114f)
                curveTo(214.001f, 64.982f, 214.229f, 64.793f, 214.412f, 64.546f)
                curveTo(214.595f, 64.298f, 214.721f, 63.998f, 214.789f, 63.644f)
                horizontalLineTo(217.001f)
                curveTo(216.945f, 64.339f, 216.741f, 64.954f, 216.387f, 65.492f)
                curveTo(216.033f, 66.024f, 215.552f, 66.442f, 214.943f, 66.744f)
                curveTo(214.333f, 67.043f, 213.617f, 67.192f, 212.795f, 67.192f)
                close()
                moveTo(223.543f, 67.192f)
                curveTo(222.585f, 67.192f, 221.754f, 66.981f, 221.051f, 66.559f)
                curveTo(220.347f, 66.137f, 219.802f, 65.547f, 219.414f, 64.788f)
                curveTo(219.031f, 64.03f, 218.839f, 63.144f, 218.839f, 62.129f)
                curveTo(218.839f, 61.115f, 219.031f, 60.227f, 219.414f, 59.464f)
                curveTo(219.802f, 58.701f, 220.347f, 58.109f, 221.051f, 57.687f)
                curveTo(221.754f, 57.265f, 222.585f, 57.054f, 223.543f, 57.054f)
                curveTo(224.502f, 57.054f, 225.333f, 57.265f, 226.036f, 57.687f)
                curveTo(226.739f, 58.109f, 227.283f, 58.701f, 227.666f, 59.464f)
                curveTo(228.054f, 60.227f, 228.248f, 61.115f, 228.248f, 62.129f)
                curveTo(228.248f, 63.144f, 228.054f, 64.03f, 227.666f, 64.788f)
                curveTo(227.283f, 65.547f, 226.739f, 66.137f, 226.036f, 66.559f)
                curveTo(225.333f, 66.981f, 224.502f, 67.192f, 223.543f, 67.192f)
                close()
                moveTo(223.556f, 65.338f)
                curveTo(224.076f, 65.338f, 224.511f, 65.195f, 224.86f, 64.91f)
                curveTo(225.21f, 64.62f, 225.47f, 64.232f, 225.64f, 63.746f)
                curveTo(225.815f, 63.261f, 225.902f, 62.72f, 225.902f, 62.123f)
                curveTo(225.902f, 61.522f, 225.815f, 60.979f, 225.64f, 60.493f)
                curveTo(225.47f, 60.003f, 225.21f, 59.613f, 224.86f, 59.323f)
                curveTo(224.511f, 59.033f, 224.076f, 58.889f, 223.556f, 58.889f)
                curveTo(223.024f, 58.889f, 222.58f, 59.033f, 222.227f, 59.323f)
                curveTo(221.877f, 59.613f, 221.615f, 60.003f, 221.44f, 60.493f)
                curveTo(221.27f, 60.979f, 221.185f, 61.522f, 221.185f, 62.123f)
                curveTo(221.185f, 62.72f, 221.27f, 63.261f, 221.44f, 63.746f)
                curveTo(221.615f, 64.232f, 221.877f, 64.62f, 222.227f, 64.91f)
                curveTo(222.58f, 65.195f, 223.024f, 65.338f, 223.556f, 65.338f)
                close()
                moveTo(234.215f, 67.173f)
                curveTo(233.444f, 67.173f, 232.754f, 66.974f, 232.144f, 66.578f)
                curveTo(231.535f, 66.182f, 231.053f, 65.606f, 230.7f, 64.852f)
                curveTo(230.346f, 64.098f, 230.169f, 63.182f, 230.169f, 62.104f)
                curveTo(230.169f, 61.013f, 230.348f, 60.092f, 230.706f, 59.342f)
                curveTo(231.068f, 58.588f, 231.556f, 58.019f, 232.17f, 57.636f)
                curveTo(232.784f, 57.248f, 233.468f, 57.054f, 234.222f, 57.054f)
                curveTo(234.797f, 57.054f, 235.27f, 57.152f, 235.641f, 57.348f)
                curveTo(236.012f, 57.54f, 236.306f, 57.772f, 236.523f, 58.045f)
                curveTo(236.74f, 58.313f, 236.909f, 58.567f, 237.028f, 58.805f)
                horizontalLineTo(237.124f)
                verticalLineTo(53.909f)
                horizontalLineTo(239.444f)
                verticalLineTo(67.0f)
                horizontalLineTo(237.169f)
                verticalLineTo(65.453f)
                horizontalLineTo(237.028f)
                curveTo(236.909f, 65.692f, 236.736f, 65.945f, 236.51f, 66.214f)
                curveTo(236.284f, 66.478f, 235.986f, 66.704f, 235.615f, 66.891f)
                curveTo(235.245f, 67.079f, 234.778f, 67.173f, 234.215f, 67.173f)
                close()
                moveTo(234.861f, 65.274f)
                curveTo(235.351f, 65.274f, 235.769f, 65.142f, 236.114f, 64.878f)
                curveTo(236.459f, 64.609f, 236.721f, 64.237f, 236.9f, 63.759f)
                curveTo(237.079f, 63.282f, 237.169f, 62.726f, 237.169f, 62.091f)
                curveTo(237.169f, 61.456f, 237.079f, 60.904f, 236.9f, 60.435f)
                curveTo(236.725f, 59.967f, 236.465f, 59.602f, 236.12f, 59.342f)
                curveTo(235.779f, 59.082f, 235.36f, 58.952f, 234.861f, 58.952f)
                curveTo(234.345f, 58.952f, 233.915f, 59.087f, 233.57f, 59.355f)
                curveTo(233.225f, 59.624f, 232.965f, 59.994f, 232.79f, 60.467f)
                curveTo(232.615f, 60.94f, 232.528f, 61.481f, 232.528f, 62.091f)
                curveTo(232.528f, 62.704f, 232.615f, 63.252f, 232.79f, 63.734f)
                curveTo(232.969f, 64.211f, 233.231f, 64.588f, 233.576f, 64.865f)
                curveTo(233.926f, 65.138f, 234.354f, 65.274f, 234.861f, 65.274f)
                close()
                moveTo(242.27f, 67.0f)
                verticalLineTo(57.182f)
                horizontalLineTo(244.584f)
                verticalLineTo(67.0f)
                horizontalLineTo(242.27f)
                close()
                moveTo(243.433f, 55.788f)
                curveTo(243.067f, 55.788f, 242.751f, 55.667f, 242.487f, 55.424f)
                curveTo(242.223f, 55.177f, 242.091f, 54.881f, 242.091f, 54.535f)
                curveTo(242.091f, 54.186f, 242.223f, 53.89f, 242.487f, 53.647f)
                curveTo(242.751f, 53.4f, 243.067f, 53.276f, 243.433f, 53.276f)
                curveTo(243.804f, 53.276f, 244.119f, 53.4f, 244.379f, 53.647f)
                curveTo(244.643f, 53.89f, 244.776f, 54.186f, 244.776f, 54.535f)
                curveTo(244.776f, 54.881f, 244.643f, 55.177f, 244.379f, 55.424f)
                curveTo(244.119f, 55.667f, 243.804f, 55.788f, 243.433f, 55.788f)
                close()
                moveTo(249.637f, 61.247f)
                verticalLineTo(67.0f)
                horizontalLineTo(247.323f)
                verticalLineTo(57.182f)
                horizontalLineTo(249.535f)
                verticalLineTo(58.85f)
                horizontalLineTo(249.65f)
                curveTo(249.876f, 58.3f, 250.236f, 57.864f, 250.73f, 57.54f)
                curveTo(251.229f, 57.216f, 251.845f, 57.054f, 252.577f, 57.054f)
                curveTo(253.255f, 57.054f, 253.845f, 57.199f, 254.348f, 57.489f)
                curveTo(254.855f, 57.778f, 255.247f, 58.198f, 255.524f, 58.748f)
                curveTo(255.805f, 59.298f, 255.944f, 59.965f, 255.94f, 60.749f)
                verticalLineTo(67.0f)
                horizontalLineTo(253.626f)
                verticalLineTo(61.106f)
                curveTo(253.626f, 60.45f, 253.455f, 59.937f, 253.114f, 59.566f)
                curveTo(252.778f, 59.195f, 252.311f, 59.01f, 251.715f, 59.01f)
                curveTo(251.31f, 59.01f, 250.95f, 59.099f, 250.634f, 59.278f)
                curveTo(250.323f, 59.453f, 250.078f, 59.707f, 249.899f, 60.039f)
                curveTo(249.724f, 60.371f, 249.637f, 60.774f, 249.637f, 61.247f)
                close()
                moveTo(262.922f, 70.886f)
                curveTo(262.091f, 70.886f, 261.377f, 70.773f, 260.781f, 70.548f)
                curveTo(260.184f, 70.326f, 259.705f, 70.028f, 259.342f, 69.653f)
                curveTo(258.98f, 69.278f, 258.729f, 68.862f, 258.588f, 68.406f)
                lineTo(260.672f, 67.901f)
                curveTo(260.766f, 68.093f, 260.902f, 68.283f, 261.081f, 68.47f)
                curveTo(261.26f, 68.662f, 261.501f, 68.82f, 261.803f, 68.943f)
                curveTo(262.11f, 69.071f, 262.496f, 69.135f, 262.96f, 69.135f)
                curveTo(263.616f, 69.135f, 264.16f, 68.975f, 264.59f, 68.656f)
                curveTo(265.021f, 68.34f, 265.236f, 67.82f, 265.236f, 67.096f)
                verticalLineTo(65.236f)
                horizontalLineTo(265.121f)
                curveTo(265.001f, 65.474f, 264.827f, 65.719f, 264.597f, 65.971f)
                curveTo(264.371f, 66.222f, 264.07f, 66.433f, 263.695f, 66.604f)
                curveTo(263.325f, 66.774f, 262.858f, 66.859f, 262.295f, 66.859f)
                curveTo(261.541f, 66.859f, 260.857f, 66.683f, 260.244f, 66.329f)
                curveTo(259.634f, 65.971f, 259.148f, 65.438f, 258.786f, 64.731f)
                curveTo(258.428f, 64.019f, 258.249f, 63.129f, 258.249f, 62.059f)
                curveTo(258.249f, 60.981f, 258.428f, 60.071f, 258.786f, 59.329f)
                curveTo(259.148f, 58.584f, 259.636f, 58.019f, 260.25f, 57.636f)
                curveTo(260.864f, 57.248f, 261.548f, 57.054f, 262.302f, 57.054f)
                curveTo(262.877f, 57.054f, 263.35f, 57.152f, 263.721f, 57.348f)
                curveTo(264.096f, 57.54f, 264.394f, 57.772f, 264.616f, 58.045f)
                curveTo(264.837f, 58.313f, 265.006f, 58.567f, 265.121f, 58.805f)
                horizontalLineTo(265.249f)
                verticalLineTo(57.182f)
                horizontalLineTo(267.531f)
                verticalLineTo(67.16f)
                curveTo(267.531f, 67.999f, 267.33f, 68.694f, 266.93f, 69.244f)
                curveTo(266.529f, 69.793f, 265.982f, 70.204f, 265.287f, 70.477f)
                curveTo(264.592f, 70.75f, 263.804f, 70.886f, 262.922f, 70.886f)
                close()
                moveTo(262.941f, 65.044f)
                curveTo(263.431f, 65.044f, 263.849f, 64.925f, 264.194f, 64.686f)
                curveTo(264.539f, 64.447f, 264.801f, 64.104f, 264.98f, 63.657f)
                curveTo(265.159f, 63.209f, 265.249f, 62.673f, 265.249f, 62.046f)
                curveTo(265.249f, 61.428f, 265.159f, 60.887f, 264.98f, 60.423f)
                curveTo(264.805f, 59.958f, 264.545f, 59.598f, 264.2f, 59.342f)
                curveTo(263.859f, 59.082f, 263.44f, 58.952f, 262.941f, 58.952f)
                curveTo(262.425f, 58.952f, 261.995f, 59.087f, 261.65f, 59.355f)
                curveTo(261.305f, 59.624f, 261.045f, 59.992f, 260.87f, 60.461f)
                curveTo(260.695f, 60.925f, 260.608f, 61.454f, 260.608f, 62.046f)
                curveTo(260.608f, 62.647f, 260.695f, 63.173f, 260.87f, 63.625f)
                curveTo(261.049f, 64.072f, 261.311f, 64.422f, 261.656f, 64.673f)
                curveTo(262.006f, 64.921f, 262.434f, 65.044f, 262.941f, 65.044f)
                close()
                moveTo(275.087f, 67.0f)
                verticalLineTo(57.182f)
                horizontalLineTo(277.298f)
                verticalLineTo(58.85f)
                horizontalLineTo(277.414f)
                curveTo(277.618f, 58.288f, 277.957f, 57.849f, 278.43f, 57.533f)
                curveTo(278.903f, 57.214f, 279.467f, 57.054f, 280.124f, 57.054f)
                curveTo(280.789f, 57.054f, 281.349f, 57.216f, 281.805f, 57.54f)
                curveTo(282.265f, 57.859f, 282.589f, 58.296f, 282.776f, 58.85f)
                horizontalLineTo(282.879f)
                curveTo(283.096f, 58.305f, 283.463f, 57.87f, 283.978f, 57.546f)
                curveTo(284.498f, 57.218f, 285.114f, 57.054f, 285.825f, 57.054f)
                curveTo(286.729f, 57.054f, 287.466f, 57.34f, 288.037f, 57.91f)
                curveTo(288.608f, 58.481f, 288.894f, 59.315f, 288.894f, 60.41f)
                verticalLineTo(67.0f)
                horizontalLineTo(286.573f)
                verticalLineTo(60.768f)
                curveTo(286.573f, 60.158f, 286.411f, 59.713f, 286.088f, 59.432f)
                curveTo(285.764f, 59.146f, 285.367f, 59.004f, 284.899f, 59.004f)
                curveTo(284.34f, 59.004f, 283.904f, 59.178f, 283.588f, 59.528f)
                curveTo(283.277f, 59.873f, 283.122f, 60.322f, 283.122f, 60.876f)
                verticalLineTo(67.0f)
                horizontalLineTo(280.852f)
                verticalLineTo(60.672f)
                curveTo(280.852f, 60.165f, 280.699f, 59.76f, 280.392f, 59.457f)
                curveTo(280.09f, 59.155f, 279.693f, 59.004f, 279.203f, 59.004f)
                curveTo(278.871f, 59.004f, 278.568f, 59.089f, 278.296f, 59.259f)
                curveTo(278.023f, 59.425f, 277.806f, 59.662f, 277.644f, 59.969f)
                curveTo(277.482f, 60.271f, 277.401f, 60.625f, 277.401f, 61.03f)
                verticalLineTo(67.0f)
                horizontalLineTo(275.087f)
                close()
                moveTo(292.688f, 70.682f)
                curveTo(292.372f, 70.682f, 292.081f, 70.656f, 291.812f, 70.605f)
                curveTo(291.548f, 70.558f, 291.337f, 70.503f, 291.179f, 70.439f)
                lineTo(291.716f, 68.636f)
                curveTo(292.053f, 68.734f, 292.353f, 68.781f, 292.617f, 68.777f)
                curveTo(292.882f, 68.773f, 293.114f, 68.69f, 293.314f, 68.528f)
                curveTo(293.519f, 68.37f, 293.691f, 68.106f, 293.832f, 67.735f)
                lineTo(294.03f, 67.204f)
                lineTo(290.47f, 57.182f)
                horizontalLineTo(292.924f)
                lineTo(295.187f, 64.597f)
                horizontalLineTo(295.289f)
                lineTo(297.558f, 57.182f)
                horizontalLineTo(300.019f)
                lineTo(296.088f, 68.189f)
                curveTo(295.905f, 68.709f, 295.662f, 69.154f, 295.36f, 69.525f)
                curveTo(295.057f, 69.9f, 294.686f, 70.185f, 294.247f, 70.381f)
                curveTo(293.813f, 70.582f, 293.293f, 70.682f, 292.688f, 70.682f)
                close()
                moveTo(310.449f, 67.173f)
                curveTo(309.678f, 67.173f, 308.987f, 66.974f, 308.378f, 66.578f)
                curveTo(307.769f, 66.182f, 307.287f, 65.606f, 306.933f, 64.852f)
                curveTo(306.58f, 64.098f, 306.403f, 63.182f, 306.403f, 62.104f)
                curveTo(306.403f, 61.013f, 306.582f, 60.092f, 306.94f, 59.342f)
                curveTo(307.302f, 58.588f, 307.79f, 58.019f, 308.403f, 57.636f)
                curveTo(309.017f, 57.248f, 309.701f, 57.054f, 310.455f, 57.054f)
                curveTo(311.031f, 57.054f, 311.504f, 57.152f, 311.874f, 57.348f)
                curveTo(312.245f, 57.54f, 312.539f, 57.772f, 312.756f, 58.045f)
                curveTo(312.974f, 58.313f, 313.142f, 58.567f, 313.261f, 58.805f)
                horizontalLineTo(313.357f)
                verticalLineTo(53.909f)
                horizontalLineTo(315.678f)
                verticalLineTo(67.0f)
                horizontalLineTo(313.402f)
                verticalLineTo(65.453f)
                horizontalLineTo(313.261f)
                curveTo(313.142f, 65.692f, 312.97f, 65.945f, 312.744f, 66.214f)
                curveTo(312.518f, 66.478f, 312.22f, 66.704f, 311.849f, 66.891f)
                curveTo(311.478f, 67.079f, 311.011f, 67.173f, 310.449f, 67.173f)
                close()
                moveTo(311.095f, 65.274f)
                curveTo(311.585f, 65.274f, 312.002f, 65.142f, 312.347f, 64.878f)
                curveTo(312.693f, 64.609f, 312.955f, 64.237f, 313.134f, 63.759f)
                curveTo(313.313f, 63.282f, 313.402f, 62.726f, 313.402f, 62.091f)
                curveTo(313.402f, 61.456f, 313.313f, 60.904f, 313.134f, 60.435f)
                curveTo(312.959f, 59.967f, 312.699f, 59.602f, 312.354f, 59.342f)
                curveTo(312.013f, 59.082f, 311.593f, 58.952f, 311.095f, 58.952f)
                curveTo(310.579f, 58.952f, 310.149f, 59.087f, 309.803f, 59.355f)
                curveTo(309.458f, 59.624f, 309.198f, 59.994f, 309.024f, 60.467f)
                curveTo(308.849f, 60.94f, 308.761f, 61.481f, 308.761f, 62.091f)
                curveTo(308.761f, 62.704f, 308.849f, 63.252f, 309.024f, 63.734f)
                curveTo(309.202f, 64.211f, 309.465f, 64.588f, 309.81f, 64.865f)
                curveTo(310.159f, 65.138f, 310.587f, 65.274f, 311.095f, 65.274f)
                close()
                moveTo(318.503f, 67.0f)
                verticalLineTo(57.182f)
                horizontalLineTo(320.747f)
                verticalLineTo(58.818f)
                horizontalLineTo(320.849f)
                curveTo(321.028f, 58.251f, 321.335f, 57.815f, 321.77f, 57.508f)
                curveTo(322.209f, 57.197f, 322.709f, 57.041f, 323.272f, 57.041f)
                curveTo(323.4f, 57.041f, 323.542f, 57.048f, 323.7f, 57.06f)
                curveTo(323.862f, 57.069f, 323.996f, 57.084f, 324.103f, 57.105f)
                verticalLineTo(59.234f)
                curveTo(324.005f, 59.2f, 323.849f, 59.17f, 323.636f, 59.144f)
                curveTo(323.427f, 59.114f, 323.225f, 59.099f, 323.029f, 59.099f)
                curveTo(322.607f, 59.099f, 322.228f, 59.191f, 321.891f, 59.374f)
                curveTo(321.559f, 59.553f, 321.297f, 59.803f, 321.105f, 60.122f)
                curveTo(320.913f, 60.442f, 320.817f, 60.81f, 320.817f, 61.228f)
                verticalLineTo(67.0f)
                horizontalLineTo(318.503f)
                close()
                moveTo(329.942f, 67.192f)
                curveTo(328.958f, 67.192f, 328.108f, 66.987f, 327.392f, 66.578f)
                curveTo(326.68f, 66.165f, 326.133f, 65.581f, 325.749f, 64.827f)
                curveTo(325.366f, 64.068f, 325.174f, 63.175f, 325.174f, 62.148f)
                curveTo(325.174f, 61.139f, 325.366f, 60.252f, 325.749f, 59.489f)
                curveTo(326.137f, 58.722f, 326.678f, 58.126f, 327.373f, 57.7f)
                curveTo(328.067f, 57.269f, 328.883f, 57.054f, 329.821f, 57.054f)
                curveTo(330.426f, 57.054f, 330.997f, 57.152f, 331.534f, 57.348f)
                curveTo(332.075f, 57.54f, 332.552f, 57.838f, 332.966f, 58.243f)
                curveTo(333.383f, 58.648f, 333.712f, 59.163f, 333.95f, 59.79f)
                curveTo(334.189f, 60.412f, 334.308f, 61.153f, 334.308f, 62.014f)
                verticalLineTo(62.724f)
                horizontalLineTo(326.261f)
                verticalLineTo(61.164f)
                horizontalLineTo(332.09f)
                curveTo(332.086f, 60.721f, 331.99f, 60.327f, 331.802f, 59.981f)
                curveTo(331.615f, 59.632f, 331.353f, 59.357f, 331.016f, 59.157f)
                curveTo(330.684f, 58.957f, 330.296f, 58.856f, 329.853f, 58.856f)
                curveTo(329.38f, 58.856f, 328.964f, 58.972f, 328.606f, 59.202f)
                curveTo(328.248f, 59.428f, 327.969f, 59.726f, 327.769f, 60.097f)
                curveTo(327.573f, 60.463f, 327.473f, 60.866f, 327.469f, 61.305f)
                verticalLineTo(62.666f)
                curveTo(327.469f, 63.237f, 327.573f, 63.727f, 327.782f, 64.136f)
                curveTo(327.991f, 64.541f, 328.283f, 64.852f, 328.658f, 65.07f)
                curveTo(329.033f, 65.283f, 329.472f, 65.389f, 329.974f, 65.389f)
                curveTo(330.311f, 65.389f, 330.616f, 65.342f, 330.888f, 65.249f)
                curveTo(331.161f, 65.151f, 331.398f, 65.008f, 331.598f, 64.82f)
                curveTo(331.798f, 64.633f, 331.949f, 64.401f, 332.052f, 64.124f)
                lineTo(334.212f, 64.367f)
                curveTo(334.076f, 64.938f, 333.816f, 65.436f, 333.432f, 65.862f)
                curveTo(333.053f, 66.284f, 332.567f, 66.612f, 331.975f, 66.847f)
                curveTo(331.383f, 67.077f, 330.705f, 67.192f, 329.942f, 67.192f)
                close()
                moveTo(339.451f, 67.198f)
                curveTo(338.829f, 67.198f, 338.268f, 67.087f, 337.77f, 66.866f)
                curveTo(337.276f, 66.64f, 336.884f, 66.308f, 336.594f, 65.869f)
                curveTo(336.308f, 65.43f, 336.165f, 64.888f, 336.165f, 64.245f)
                curveTo(336.165f, 63.691f, 336.268f, 63.233f, 336.472f, 62.871f)
                curveTo(336.677f, 62.508f, 336.956f, 62.219f, 337.31f, 62.001f)
                curveTo(337.663f, 61.784f, 338.062f, 61.62f, 338.505f, 61.509f)
                curveTo(338.952f, 61.394f, 339.415f, 61.311f, 339.892f, 61.26f)
                curveTo(340.467f, 61.2f, 340.934f, 61.147f, 341.292f, 61.1f)
                curveTo(341.65f, 61.049f, 341.91f, 60.972f, 342.072f, 60.87f)
                curveTo(342.238f, 60.764f, 342.321f, 60.599f, 342.321f, 60.378f)
                verticalLineTo(60.34f)
                curveTo(342.321f, 59.858f, 342.178f, 59.485f, 341.893f, 59.221f)
                curveTo(341.607f, 58.957f, 341.196f, 58.825f, 340.659f, 58.825f)
                curveTo(340.092f, 58.825f, 339.643f, 58.948f, 339.31f, 59.195f)
                curveTo(338.982f, 59.443f, 338.761f, 59.734f, 338.646f, 60.071f)
                lineTo(336.485f, 59.764f)
                curveTo(336.656f, 59.168f, 336.937f, 58.669f, 337.329f, 58.269f)
                curveTo(337.721f, 57.864f, 338.2f, 57.561f, 338.767f, 57.361f)
                curveTo(339.334f, 57.156f, 339.96f, 57.054f, 340.646f, 57.054f)
                curveTo(341.119f, 57.054f, 341.59f, 57.109f, 342.059f, 57.22f)
                curveTo(342.528f, 57.331f, 342.956f, 57.514f, 343.344f, 57.77f)
                curveTo(343.732f, 58.021f, 344.043f, 58.364f, 344.277f, 58.799f)
                curveTo(344.516f, 59.234f, 344.635f, 59.777f, 344.635f, 60.429f)
                verticalLineTo(67.0f)
                horizontalLineTo(342.411f)
                verticalLineTo(65.651f)
                horizontalLineTo(342.334f)
                curveTo(342.193f, 65.924f, 341.995f, 66.18f, 341.739f, 66.418f)
                curveTo(341.488f, 66.653f, 341.17f, 66.842f, 340.787f, 66.987f)
                curveTo(340.408f, 67.128f, 339.962f, 67.198f, 339.451f, 67.198f)
                close()
                moveTo(340.052f, 65.498f)
                curveTo(340.516f, 65.498f, 340.919f, 65.406f, 341.26f, 65.223f)
                curveTo(341.601f, 65.035f, 341.863f, 64.788f, 342.046f, 64.481f)
                curveTo(342.234f, 64.175f, 342.327f, 63.84f, 342.327f, 63.478f)
                verticalLineTo(62.321f)
                curveTo(342.255f, 62.381f, 342.131f, 62.436f, 341.957f, 62.487f)
                curveTo(341.786f, 62.538f, 341.594f, 62.583f, 341.381f, 62.621f)
                curveTo(341.168f, 62.66f, 340.957f, 62.694f, 340.749f, 62.724f)
                curveTo(340.54f, 62.754f, 340.359f, 62.779f, 340.205f, 62.8f)
                curveTo(339.86f, 62.847f, 339.551f, 62.924f, 339.278f, 63.03f)
                curveTo(339.006f, 63.137f, 338.79f, 63.286f, 338.633f, 63.478f)
                curveTo(338.475f, 63.666f, 338.396f, 63.908f, 338.396f, 64.207f)
                curveTo(338.396f, 64.633f, 338.552f, 64.954f, 338.863f, 65.172f)
                curveTo(339.174f, 65.389f, 339.57f, 65.498f, 340.052f, 65.498f)
                close()
                moveTo(347.322f, 67.0f)
                verticalLineTo(57.182f)
                horizontalLineTo(349.533f)
                verticalLineTo(58.85f)
                horizontalLineTo(349.648f)
                curveTo(349.853f, 58.288f, 350.192f, 57.849f, 350.665f, 57.533f)
                curveTo(351.138f, 57.214f, 351.702f, 57.054f, 352.359f, 57.054f)
                curveTo(353.023f, 57.054f, 353.584f, 57.216f, 354.04f, 57.54f)
                curveTo(354.5f, 57.859f, 354.824f, 58.296f, 355.011f, 58.85f)
                horizontalLineTo(355.114f)
                curveTo(355.331f, 58.305f, 355.697f, 57.87f, 356.213f, 57.546f)
                curveTo(356.733f, 57.218f, 357.349f, 57.054f, 358.06f, 57.054f)
                curveTo(358.964f, 57.054f, 359.701f, 57.34f, 360.272f, 57.91f)
                curveTo(360.843f, 58.481f, 361.128f, 59.315f, 361.128f, 60.41f)
                verticalLineTo(67.0f)
                horizontalLineTo(358.808f)
                verticalLineTo(60.768f)
                curveTo(358.808f, 60.158f, 358.646f, 59.713f, 358.322f, 59.432f)
                curveTo(357.998f, 59.146f, 357.602f, 59.004f, 357.133f, 59.004f)
                curveTo(356.575f, 59.004f, 356.138f, 59.178f, 355.823f, 59.528f)
                curveTo(355.512f, 59.873f, 355.356f, 60.322f, 355.356f, 60.876f)
                verticalLineTo(67.0f)
                horizontalLineTo(353.087f)
                verticalLineTo(60.672f)
                curveTo(353.087f, 60.165f, 352.934f, 59.76f, 352.627f, 59.457f)
                curveTo(352.324f, 59.155f, 351.928f, 59.004f, 351.438f, 59.004f)
                curveTo(351.106f, 59.004f, 350.803f, 59.089f, 350.53f, 59.259f)
                curveTo(350.258f, 59.425f, 350.04f, 59.662f, 349.878f, 59.969f)
                curveTo(349.717f, 60.271f, 349.636f, 60.625f, 349.636f, 61.03f)
                verticalLineTo(67.0f)
                horizontalLineTo(347.322f)
                close()
                moveTo(371.596f, 59.777f)
                lineTo(369.487f, 60.007f)
                curveTo(369.427f, 59.794f, 369.322f, 59.594f, 369.173f, 59.406f)
                curveTo(369.028f, 59.219f, 368.832f, 59.068f, 368.585f, 58.952f)
                curveTo(368.338f, 58.837f, 368.036f, 58.78f, 367.678f, 58.78f)
                curveTo(367.196f, 58.78f, 366.791f, 58.884f, 366.463f, 59.093f)
                curveTo(366.139f, 59.302f, 365.979f, 59.572f, 365.984f, 59.905f)
                curveTo(365.979f, 60.19f, 366.084f, 60.423f, 366.297f, 60.602f)
                curveTo(366.514f, 60.78f, 366.872f, 60.928f, 367.371f, 61.043f)
                lineTo(369.045f, 61.401f)
                curveTo(369.974f, 61.601f, 370.665f, 61.918f, 371.116f, 62.353f)
                curveTo(371.572f, 62.788f, 371.803f, 63.356f, 371.807f, 64.06f)
                curveTo(371.803f, 64.678f, 371.621f, 65.223f, 371.264f, 65.696f)
                curveTo(370.91f, 66.165f, 370.418f, 66.531f, 369.787f, 66.796f)
                curveTo(369.156f, 67.06f, 368.432f, 67.192f, 367.614f, 67.192f)
                curveTo(366.412f, 67.192f, 365.445f, 66.94f, 364.712f, 66.438f)
                curveTo(363.979f, 65.93f, 363.542f, 65.225f, 363.401f, 64.322f)
                lineTo(365.658f, 64.104f)
                curveTo(365.76f, 64.548f, 365.977f, 64.882f, 366.31f, 65.108f)
                curveTo(366.642f, 65.334f, 367.075f, 65.447f, 367.607f, 65.447f)
                curveTo(368.157f, 65.447f, 368.598f, 65.334f, 368.93f, 65.108f)
                curveTo(369.267f, 64.882f, 369.435f, 64.603f, 369.435f, 64.271f)
                curveTo(369.435f, 63.989f, 369.327f, 63.757f, 369.109f, 63.574f)
                curveTo(368.896f, 63.391f, 368.564f, 63.25f, 368.112f, 63.152f)
                lineTo(366.438f, 62.8f)
                curveTo(365.496f, 62.604f, 364.799f, 62.274f, 364.347f, 61.81f)
                curveTo(363.896f, 61.341f, 363.672f, 60.749f, 363.676f, 60.033f)
                curveTo(363.672f, 59.428f, 363.836f, 58.903f, 364.168f, 58.46f)
                curveTo(364.505f, 58.013f, 364.972f, 57.668f, 365.568f, 57.425f)
                curveTo(366.169f, 57.178f, 366.862f, 57.054f, 367.646f, 57.054f)
                curveTo(368.796f, 57.054f, 369.702f, 57.299f, 370.362f, 57.789f)
                curveTo(371.027f, 58.279f, 371.438f, 58.942f, 371.596f, 59.777f)
                close()
            }
        }
        .build()
        return _logoComplex!!
    }

private var _logoComplex: ImageVector? = null
